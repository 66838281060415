<template>
    <div class="selectInput">
        <div :class="['selectInput-wrap']">
            <Input
                v-model="model"
                size="large"
                type="textarea"
                placeholder="请输入..."
                @on-blur="onBlur"
                @input="onInput"
                :clearable="true" />
            <div class="select" v-if="isShow" :style="`height:${menuHeight}`">
                <div
                    v-for="(item, index) in list"
                    class="list-item"
                    :key="index"
                    @mousedown="onClickItem(item)"
                    v-html="item.questionSta"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { request } from '@/network/request';

export default {
    name: 'SelectInput',
    props: {
        value: {
            type: String
        },
        url: {
            type: String
        },
        consultCode: {
            type: String
        }
    },
    data() {
        return {
            isShow: false,
            list: [],
            model: ''
        };
    },
    watch: {
        value(val) {
            this.model = val
        }
    },
    computed: {
        // 下拉框的高度
        menuHeight() {
            return this.list.length * 50 > 200 ? 200 + 'px' : `${this.list.length * 50}px`;
        }
    },
    mounted() {
        this.doDebounceSearch = this.getDebounce(this.onSearch, 500);
    },

    methods: {
        onClickItem(item) {
            this.model = item.value;
            this.isShow = false;
            this.list = [];
            this.$emit('selectItem', item);
        },
        onInput() {
            this.$emit('input', this.model)
            this.doDebounceSearch();
        },
        onBlur() {
            this.isShow = false;
            this.list = [];
        },
        onSearch() {
            if (!this.model) {
                this.isShow = false;
                this.list = [];
                return;
            }

            const params = this.getPageQueryParams(
                { keyword: this.model, consultCode: this.consultCode },
                1,
                10
            );

            request
                .get(this.url, params)
                .then(data => {
                    if (data.success) {
                        this.list = this.highlightResult(data.result.list || []);
                        this.isShow = true;
                    } else {
                        this.$$Message.error(data.message);
                    }
                })
                .finally();
        },
        highlightResult(list) {
            return list.map(item => {
                item.value = item.questionSta;
                item.questionSta = item.questionSta.replace(
                    this.model,
                    `<span class="highlight">${this.model}</span>`
                );
                return item;
            });
        }
    }
};
</script>
<style lang="less" scoped>
::v-deep .ivu-input-icon-clear {
    position: absolute;
    top: -9px;
}

::v-deep .ivu-icon-ios-search {
    margin-left: 5px;
    font-size: 20px;
    cursor: pointer;
}

.special {
    /deep/ input {
        color: #fc787f;
    }
}

.selectInput {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .selectInput-wrap {
        position: relative;
        width: 100%;

        .select {
            width: 100%;
            position: absolute;
            left: 0;
            z-index: 999;
            overflow: auto;
            padding: 0 15px;
            background-color: #fff;
            cursor: pointer;
            border: 1px solid #bec8d2;

            .list-item {
                padding: 10px 3px;
                border-bottom: 0.5px solid #bec8d2;
                font-size: 12px;
                color: #3c4565;
                font-weight: 600;

                /deep/ .highlight {
                    font-weight: 600;
                    color: #1fa4ff;
                }
            }
        }
    }
}
</style>
