<template>
    <div class="content-wrap">
        <!-- 查询区域 -->
        <sv-search @onSearch="doSearch" ref="search" :labelWidth="100">
            <item label="问题编码" type="textbox" name="id" />
            <item label="问题分类" type="combobox" url="/consult/combo/questionCategory" name="consultType" />
            <item label="状态" type="combobox" url="/consult/combo/status" name="replyStatus" />
            <item label="关键词" type="textbox" name="keyword" />
            <item label="提问时间起" type="datebox" name="startDate" />
            <item label="提问时间止" type="datebox" name="endDate" />
            <item label="配件号" type="textbox" name="partCode" />
            <item label="配件名" type="textbox" name="partName" />
            <item label="手册分类" type="combobox" url="/manual/manual/classify/list" name="manualType" />
        </sv-search>
        <!-- 表格区域 -->
        <sv-grid ref="grid" listTitle="咨询查询结果" :rownumber="true" @getDataList="getDataList">
            <div slot="grid-top" class="total">
                共<span class="total-num">{{ num }}</span> 条数据
            </div>
            <urls>
                <item name="read" url="/consult/engineering/consult/page" />
            </urls>
            <toolbars> </toolbars>
            <columns>
                <item title="问题编码" key="id" width="120px" linkUrl="/engineering/engineeringDetail?id={id}" />
                <item title="问题分类" key="consultTypeName" width="120px" />
                <item title="问题状态" key="replyStatusName" width="120px" />
                <item title="提问时间" key="createdOn" width="140px" />
                <item title="回复人" key="claimBy" width="120px" />
                <item title="回复时间" key="claimOn" width="140px" />
                <item title="品牌" key="brandName" width="120px" />
                <item title="配件编号" key="partCode" width="120px" />
                <item title="配件名称" key="partName" width="120px" />
                <item title="手册类型" key="classifyName" flex="1" />
            </columns>
        </sv-grid>
    </div>
</template>

<script>
import { crud } from '@/mixins/crud';

export default {
    mixins: [crud],
    data() {
        return {
            num: 0
        };
    },
    created() { },
    mounted() { },
    methods: {
        getDataList(list) {
            this.num = list.result.total;
        }
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    padding: 0 20px;

    /deep/.query-inner {
        border-bottom: 1px @border-color solid;
    }

    /deep/.shopping-cart-top {
        padding: 5px 100px;

        .total-info {
            display: flex;
            align-items: center;

            .total-price {
                font-size: 17px;
                color: #00176b;
                line-height: 24px;
                font-weight: 600;
                margin-right: 60px;

                .price {
                    color: #d41313;
                }
            }

            .goods {
                font-size: 17px;
                color: #00176b;
                font-weight: 600;
            }
        }
    }

    /deep/.ivu-btn-default {
        color: #005ad5;
        line-height: 20px;
    }

    .total {
        margin-left: 15px;
        font-size: 12px;
        font-family: PingFangSC-Regular;

        .total-num {
            color: #b60004;
        }
    }
}
</style>
