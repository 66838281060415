<template>
    <div class="main">
        <Tabs :value="activeName" @on-click="tabClick">
            <TabPane label="我的工程咨询" name="Engineering">
                <Engineering ref="Engineering" />
            </TabPane>
            <TabPane label="工时咨询" name="Workhour">
                <Workhour ref="Workhour" @selectItem="selectItem" />
            </TabPane>
            <TabPane label="专用工具咨询" name="Tools">
                <Tools ref="Tools" @selectItem="selectItem" />
            </TabPane>
            <TabPane label="维修手册咨询" name="Manual">
                <Manual ref="Manual" @selectItem="selectItem" />
            </TabPane>
        </Tabs>

        <!-- 弹窗 -->
        <Modal v-model="modal" :mask-closable="false">
            <div class="modal-body">
                <!-- 左边 -->
                <div class="left">
                    <div class="title">
                        <h3>常见问题</h3>
                        <div>
                            <i class="iconfont icon-up-arrow2" @click="handleMove('up')"></i>
                            <i class="iconfont icon-down-arraw2" @click="handleMove('down')"></i>
                        </div>
                    </div>
                    <div class="search">
                        <Input
                            search
                            enter-button="查询"
                            ref="keyword"
                            v-model="keyword"
                            placeholder="搜索目录"
                            @on-change="doSearch"
                            @on-clear="doSearch"
                            @on-search="doSearch"
                            :clearable="true" />
                    </div>
                    <div class="left-content">
                        <div
                            v-for="item in list"
                            :class="{ 'list-cell': true, actived: item.selected }"
                            @click="handleRowClick(item)"
                            :key="item.id">
                            <div>
                                <div class="topic-title" v-html="item.questionSta"></div>
                            </div>
                        </div>
                    </div>
                    <div class="btns">
                        <Button class="cancel" type="primary" @click="cancel">取消</Button>
                        <Button type="primary" @click="cancel">无相关问题</Button>
                    </div>
                </div>
                <!-- 右边 -->
                <div class="right">
                    <div class="title">
                        <span>问题答案</span>
                        <Button type="primary" @click="cantSolve">答案不能解决</Button>
                    </div>
                    <div class="right-content">
                        <div class="noQues" v-if="!isCheckedItem">
                            <i class="iconfont icon-a-tis_CombinedShape"></i>
                            <p>请先选择问题</p>
                        </div>
                        <div class="Ques" v-else>
                            <div class="grid-wrap">
                                <div class="view-list-wrap">
                                    <div>{{ checkedItem.answerSta }}</div>
                                    <div class="image-wrap" v-if="checkedItem.res">
                                        <img
                                            v-if="
                                                checkedItem.res.extension &&
                                                (checkedItem.res.extension === 'jpg' ||
                                                    checkedItem.res.extension === 'png')
                                            "
                                            :src="checkedItem.res.path"
                                            v-defaultImg="nopic" />
                                        <div :title="checkedItem.res.name">
                                            {{ checkedItem.res.name }}
                                        </div>
                                        <div class="download" @click="download(checkedItem.res.path)">下载</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
import { request } from '@/network/request';
import Engineering from './engineeringPages/Engineering';
import Workhour from './engineeringPages/Workhour';
import Tools from './engineeringPages/Tools';
import Manual from './engineeringPages/Manual';
import nopic from '@/assets/images/nopic.png';

export default {
    components: {
        Engineering,
        Workhour,
        Tools,
        Manual
    },
    data() {
        return {
            activeName: 'Engineering',
            modal: false,
            keyword: '',
            list: [],
            pagingData: {
                page: 1,
                size: 20,
                total: 0
            },
            showLoading: false,
            isCheckedItem: false,
            checkedItem: {},
            nopic: nopic,
            question: '',
            module: 'Engineering'
        };
    },
    watch: {
        modal(val) {
            if (val) {
                this.loadData();
            } else {
                this.closeModal();
            }
        }
    },
    created() {
        this.loadData();
    },
    mounted() { },
    methods: {
        selectItem(val) {
            this.modal = true;
            this.keyword = val;
        },
        tabClick(name) {
            switch (name) {
                case 'Engineering':
                    this.module = name;
                    break;
                case 'Workhour':
                case 'Tools':
                case 'Manual':
                    this.module = name;
                    this.modal = true;
                    break;
            }
        },
        doSearch() {
            this.loadData();
        },
        async loadData() {
            const consultCode = await this.getPage();

            const { page, size } = this.pagingData;
            const params = this.getPageQueryParams({ keyword: this.keyword, consultCode: consultCode }, page, size);
            this.showLoading = true;

            request
                .get('/consult/consult/sta/page', params)
                .then(data => {
                    if (data.success) {
                        this.list = data.result.list || [];
                        this.pagingData.total = data.result.total;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },
        getPage() {
            if (this.module === 'Manual') {
                return '2';
            } else if (this.module === 'Workhour') {
                return '0';
            } else if (this.module === 'Tools') {
                return '1';
            }
        },
        getHignlight(data) {
            let list = data.result.list || [];
            list.forEach(item => {
                item.questionSta = this.highlightNode(item.questionSta, this.keyword);
            });

            return list;
        },

        // 高亮节点
        highlightNode(title, keyword) {
            if (!this.isEmpty(keyword)) {
                const regexp = new RegExp(keyword, 'g');
                return title.replace(regexp, `<span style="color:#616DF1;">${keyword}</span>`);
            } else {
                return title;
            }
        },

        handleRowClick(item) {
            this.isCheckedItem = true;
            this.list.map(item => (item.selected = false));
            this.$set(item, 'selected', true);
            this.checkedItem = item;
        },

        download(url) {
            window.open(url);
        },

        handleMove(action) {
            if (!this.isCheckedItem) return;

            const selectedItem = this.list.find(item => item.selected);
            const index = this.list.indexOf(selectedItem);

            switch (action) {
                case 'up':
                    if (index === 0) return;
                    // this.list.splice(index - 1, 0, this.list.splice(index, 1)[0]);
                    this.handleRowClick(this.list[index - 1]);
                    break;
                case 'down':
                    if (index === this.list.length - 1) return;
                    // this.list.splice(index + 1, 0, this.list.splice(index, 1)[0]);
                    this.handleRowClick(this.list[index + 1]);
                    break;
            }
        },

        cancel() {
            this.closeModal();
        },

        cantSolve() {
            if (!this.checkedItem) return;
            this.question = this.checkedItem.questionSta;
            const brandCode = this.checkedItem.brandCode;
            const seriesCode = this.checkedItem.seriesCode;

            if (this.module === 'Workhour') {
                this.$refs.Workhour.form.questionDesc = this.question;
                this.$refs.Workhour.form.brandCode = brandCode;
                this.$refs.Workhour.form.seriesCode = seriesCode;
            } else if (this.module === 'Tools') {
                this.$refs.Tools.form.questionDesc = this.question;
                this.$refs.Tools.form.brandCode = brandCode;
                this.$refs.Tools.form.seriesCode = seriesCode;
            } else if (this.module === 'Manual') {
                this.$refs.Manual.form.questionDesc = this.question;
                this.$refs.Manual.form.brandCode = brandCode;
                this.$refs.Manual.form.seriesCode = seriesCode;
            }

            this.closeModal();
        },

        closeModal() {
            this.modal = false;
            this.keyword = '';
            this.list = [];
            this.pagingData = {
                page: 1,
                size: 20,
                total: 0
            };
            this.showLoading = false;
            this.isCheckedItem = false;
            this.checkedItem = {};
            this.question = '';
            this.list.map(item => (item.selected = false));
        }
    }
};
</script>

<style lang="less" scoped>
.main {
    height: 100%;

    /deep/.ivu-tabs-nav-container {
        width: 70%;
        margin: 0 auto;
        font-size: 17px;
        color: #00176b;
        font-weight: 600;
    }

    /deep/.ivu-tabs-nav-scroll {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/deep/.ivu-modal {
    width: 800px !important;
    height: 500px !important;

    .ivu-modal-content {
        height: 100%;

        .ivu-modal-header {
            height: 0px;
        }

        .ivu-modal-body {
            height: 99%;

            .modal-body {
                flex-direction: row;
                height: 100%;

                .left {
                    width: 40%;
                    height: 100%;
                    padding: 10px;
                    border-right: 1px @border-color solid;

                    .title {
                        display: flex;
                        justify-content: space-between;

                        i:first-child {
                            margin-right: 10px;
                        }
                    }

                    .search {
                        margin: 5px 0 0 0;
                    }

                    .left-content {
                        height: 80%;
                        overflow-y: auto;

                        .list-cell {
                            display: flex;
                            cursor: pointer;
                            justify-content: space-between;
                            border-bottom: 1px dotted @border-color;
                            padding: 10px;
                            padding-left: 5px;

                            .topic-title {
                                font-weight: 600;

                                /deep/ span {
                                    font-weight: 600;
                                    color: @primary-color;
                                }
                            }

                            &:hover {
                                background: @light-primary-color;
                            }

                            &.actived {
                                background: @light-primary-color;
                            }
                        }
                    }

                    .btns {
                        width: 100%;
                        margin-top: 10px;
                        margin: 0 auto;

                        .cancel {
                            margin-left: 55px;
                        }

                        .ivu-btn {
                            margin-right: 10px;
                        }
                    }
                }

                .right {
                    width: 60%;
                    height: 100%;
                    padding: 10px;

                    .title {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        font-weight: 600;
                        font-size: 16px;

                        button {
                            margin-right: 15px;
                        }
                    }

                    .right-content {
                        position: relative;
                        height: 95%;
                        background-color: #f2f2f2;

                        .noQues {
                            text-align: center;
                            font-size: 14px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            i {
                                font-size: 50px;
                            }
                        }

                        .Ques {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            font-size: 14px;

                            .grid-wrap {
                                flex: 1;
                                display: flex;
                                padding: 16px;

                                .view-list-wrap {
                                    flex: 1;
                                    display: flex;
                                    flex-direction: column;

                                    .image-wrap {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        // justify-content: center;
                                        // align-items: center;

                                        img {
                                            width: 80%;
                                            height: 80%;
                                        }

                                        .download {
                                            color: #616df2;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .ivu-modal-footer {
            display: none;
        }

        .ivu-modal-close {
            display: none;
        }
    }
}
</style>
