<template>
    <div class="content-wrap">
        <div class="main">
            <!-- 诊断仪相关信息 -->
            <div class="baseForm">
                <div class="detail">
                    <Form ref="form" :model="form" :rules="ruleValidate" label-position="right">
                        <Row>
                            <Col span="8">
                            <FormItem label="经销商代码：" :label-width="130" prop="dsAccount"><Input v-model="form.dsAccount"
                                    disabled="disabled" />
                            </FormItem>
                            </Col>
                            <Col span="8">
                            <FormItem label="经销商名称：" :label-width="130" prop="dsMobile"><Input v-model="form.dsMobile"
                                    disabled="disabled" />
                            </FormItem>
                            </Col>
                            <Col span="8">
                            <FormItem label="提问人：" :label-width="130" prop="questionMan"><Input
                                    v-model="form.questionMan" />
                            </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="8">
                            <FormItem label="联系电话：" :label-width="130" prop="questionMobile"><Input
                                    v-model="form.questionMobile" />
                            </FormItem>
                            </Col>
                            <Col span="8">
                            <FormItem label="品牌：" :label-width="130" prop="brandCode"><Select v-model="form.brandCode">
                                    <Option v-for="item in brandList" :value="item.code" :key="item.code">{{
                                        item.name
                                    }}</Option>
                                </Select>
                            </FormItem>
                            </Col>
                            <Col span="8">
                            <FormItem label="车系：" :label-width="130" prop="seriesCode"><Select
                                    v-model="form.seriesCode">
                                    <Option v-for="item in seriesList" :value="item.code" :key="item.code">{{
                                        item.name
                                    }}</Option>
                                </Select>
                            </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="8">
                            <FormItem label="手册分类：" :label-width="130" prop="classifyId">
                                <Select v-model="form.classifyId">
                                    <Option v-for="item in classList" :value="item.code" :key="item.code">{{
                                        item.name
                                    }}</Option>
                                </Select>
                            </FormItem>
                            </Col>
                            <Col span="8">
                            <FormItem label="手册章节：" :label-width="130" prop="manualChapter"><Input
                                    v-model="form.manualChapter" />
                            </FormItem>
                            </Col>
                            <Col span="8">
                            <FormItem label="手册页码：" :label-width="130" prop="manualPage"><Input
                                    v-model="form.manualPage" />
                            </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                            <FormItem label="问题描述：" :label-width="130" prop="questionDesc">
                                <selectSearch
                                    @selectItem="selectItem"
                                    @inputChange="inputChange"
                                    v-model="form.questionDesc"
                                    :url="url"
                                    :consultCode="consultCode"></selectSearch>
                            </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                            <FormItem label="附件：" class="attach">
                                <addUpload
                                    btnUplodText="浏览"
                                    class="btn-upload"
                                    url="/api/consult/res/upload"
                                    :formDynamic="formDynamic"
                                    @handleUpload="handleUpload"
                                    @handleAdd="handleAdd(formDynamic)">
                                </addUpload>
                            </FormItem>
                            </Col>
                        </Row>

                        <div class="btns">
                            <Button type="primary" @click="doSubmit">提交</Button>
                            <Button @click="reset">重置信息</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { request } from '@/network/request';
import nopic from '@/assets/images/nopic.png';
import addUpload from '../engineeringDetail/common/add-upload.vue';
import { storage } from '@/common/storage';
import selectSearch from '../common/selectSearch.vue';

export default {
    components: {
        addUpload,
        selectSearch
    },
    data() {
        const userInfo = JSON.parse(storage.getUserInfo());

        return {
            data: {},
            nopic: nopic,
            form: {
                dsAccount: userInfo.enterpriseName,
                dsMobile: userInfo.username,
                questionMan: '',
                questionMobile: '',
                brandCode: '',
                seriesCode: '',
                classifyId: '',
                manualChapter: '',
                manualPage: '',
                questionDesc: '',
                resIds: []
            },
            files: [],
            formDynamic: [
                {
                    value: '',
                    index: 1
                }
            ],
            index: 1,
            brandList: [],
            seriesList: [],
            classList: [],
            ruleValidate: {
                dsAccount: [{ required: true, message: '请填入数据', trigger: 'blur' }],
                dsMobile: [{ required: true, message: '请填入数据', trigger: 'blur' }],
                questionMan: [{ required: true, message: '请填入数据', trigger: 'blur' }],
                questionMobile: [{ required: true, message: '请填入数据', trigger: 'blur' }],
                brandCode: [{ required: true, message: '请填入数据', trigger: 'blur' }],
                seriesCode: [{ required: true, message: '请填入数据', trigger: 'blur' }],
                classifyId: [{ required: true, message: '请填入数据', trigger: 'blur' }],
                manualChapter: [{ required: true, message: '请填入数据', trigger: 'blur' }],
                manualPage: [{ required: true, message: '请填入数据', trigger: 'blur' }],
                questionDesc: [{ required: true, message: '请填入数据', trigger: 'blur' }]
            },
            url: '/consult/consult/sta/page',
            consultCode: '2'
        };
    },
    created() {
        this.getBrandListList();
        this.getClassList();
    },
    watch: {
        'form.brandCode'(val) {
            if (!val) return;
            this.getSeriesList(val);
        }
    },
    mounted() { },
    methods: {
        inputChange(val) {
            this.form.questionDesc = val;
        },

        selectItem(item) {
            this.$emit('selectItem', item.value);
        },
        getClassList() {
            request.get('/manual/manual/classify/list').then(data => {
                if (data.success) {
                    this.classList = data.result;
                }
            });
        },
        getBrandListList() {
            request
                .get('/manual/combo/brand/list')
                .then(data => {
                    if (data.success) {
                        this.brandList = data.result;
                    }
                })
                .finally(() => { });
        },
        getSeriesList(val) {
            const url = '/manual/combo/series/list?brandCode=' + val;

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        this.seriesList = data.result;
                    }
                })
                .finally(() => { });
        },
        async doSubmit() {
            const isValid = await this.$refs.form.validate();
            const params = this.form;

            if (isValid) {
                request
                    .post('/consult/engineering/consult/manual/create', params)
                    .then(data => {
                        if (data.success) {
                            this.$Message.success('提交成功');
                            this.reset();
                            location.reload()  // 刷新页面
                        } else {
                            this.$Message.error(data.message);
                        }
                    })
                    .finally(() => { });
            }
        },
        reset() {
            const userInfo = JSON.parse(storage.getUserInfo());
            this.$refs.form.resetFields();
            this.formDynamic = [
                {
                    value: '',
                    index: 1
                }
            ]
            this.form.dsAccount = userInfo.enterpriseName;
            this.form.dsMobile = userInfo.username;
        },
        handleUpload(res) {
            this.form.resIds = res;
        },
        handleAdd(list) {
            if (list.slice(-1)[0].value === '') {
                this.$Message.error('请先上传附件');
                return;
            }

            this.index++;
            if (list.length < 5) {
                list.push({
                    value: '',
                    index: this.index
                });
            } else {
                this.$Message.error('最多只能添加5个附件');
            }
        }
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .main {
        width: 70%;
        min-width: 1000px;
        margin: 0 auto;
        height: 100%;

        .baseForm {
            padding: 5px 0px;
            margin-top: 10px;
            border: 1px @border-color solid;

            .detail {
                display: flex;
                padding: 15px 15px;

                .ivu-form {
                    margin: 10px 15px;
                    width: 100%;

                    .ivu-row:last-child {
                        margin-left: 100px !important;
                    }

                    .ivu-form-item {
                        width: 100%;
                        margin: 0 auto;

                        /deep/.btn-upload {
                            margin-left: 54px !important;
                        }
                    }

                    /deep/.txtarea {
                        textarea {
                            width: 100%;
                            height: 100px;
                        }
                    }

                    .list {
                        border: 1px @border-color solid;
                        padding: 0 10px;
                        background-color: #f8f8f8;

                        .item {
                            border-bottom: 1px @border-color solid;
                        }

                        .item:last-child {
                            border: none;
                        }
                    }
                }

                .btns {
                    width: 100%;
                    text-align: center;

                    button {
                        margin: 0 auto;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    /deep/.ivu-row {
        margin-bottom: 10px;
    }

    /deep/.attach {
        margin-left: 76px !important;
    }
}
</style>
